export const GoogleIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.34 20.2954C32.34 19.3736 32.2573 18.4873 32.1036 17.6364H19.86V22.665H26.8563C26.555 24.29 25.6391 25.6668 24.2623 26.5886V29.8504H28.4636C30.9218 27.5873 32.34 24.2545 32.34 20.2954Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.86 33C23.37 33 26.3127 31.8359 28.4636 29.8504L24.2623 26.5886C23.0982 27.3686 21.6091 27.8295 19.86 27.8295C16.4741 27.8295 13.6082 25.5427 12.5859 22.47H8.24274V25.8382C10.3818 30.0868 14.7782 33 19.86 33Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5859 22.47C12.3259 21.69 12.1782 20.8568 12.1782 20C12.1782 19.1432 12.3259 18.31 12.5859 17.53V14.1618H8.24271C7.36226 15.9168 6.85999 17.9023 6.85999 20C6.85999 22.0977 7.36226 24.0832 8.24271 25.8382L12.5859 22.47Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.86 12.1705C21.7686 12.1705 23.4823 12.8264 24.8296 14.1145L28.5582 10.3859C26.3068 8.28818 23.3641 7 19.86 7C14.7782 7 10.3818 9.91318 8.24274 14.1618L12.5859 17.53C13.6082 14.4573 16.4741 12.1705 19.86 12.1705Z"
      fill="#EA4335"
    />
  </svg>
);
