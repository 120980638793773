import React from "react";

export const PlaceholderIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 16H16V32H32V16Z" fill="#B62321" />
    <path opacity="0.4" d="M10 44C13.3 44 16 41.3 16 38V32H10C6.7 32 4 34.7 4 38C4 41.3 6.7 44 10 44Z" fill="#B62321" />
    <path opacity="0.4" d="M10 16H16V10C16 6.7 13.3 4 10 4C6.7 4 4 6.7 4 10C4 13.3 6.7 16 10 16Z" fill="#B62321" />
    <path opacity="0.4" d="M32 16H38C41.3 16 44 13.3 44 10C44 6.7 41.3 4 38 4C34.7 4 32 6.7 32 10V16Z" fill="#B62321" />
    <path opacity="0.4" d="M38 44C41.3 44 44 41.3 44 38C44 34.7 41.3 32 38 32H32V38C32 41.3 34.7 44 38 44Z" fill="#B62321" />
  </svg>
);
