import React from "react";

/**
 * ArrowLeft component.
 * Renders an SVG arrow pointing to the left.
 */
export const ArrowLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.91406 15.0857L11.4999 11.4999C12.2809 10.7189 12.2809 9.45253 11.4998 8.67148L7.91406 5.08569"
      stroke="#FFEDEA"
      strokeOpacity="0.9"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
