import { Box, type BoxProps, Typography } from "@mui/material";
import React, { type FC } from "react";

import { BackIcon } from "./icons";
import { PrevRoute } from "./PrevRoute";

interface PageTitleProps extends BoxProps {
  title: string;

  showBackIcon?: boolean;
}

/**
 * Represents a page title component.
 *
 * @component
 * @example
 * ```tsx
 * <PageTitle title="My Page Title" />
 * ```
 */
export const PageTitle: FC<PageTitleProps> = (props) => {
  const { title, showBackIcon, ...boxProps } = props;

  return (
    <Box component="header" display="flex" alignItems="center" gap={2} {...boxProps}>
      {showBackIcon && (
        <PrevRoute>
          <BackIcon />
        </PrevRoute>
      )}
      <Typography variant="h1">{props.title}</Typography>
    </Box>
  );
};
