import { Typography } from "@mui/material";
import type { FC, ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

export const Label: FC<IProps> = (props) => (
  <Typography component="label" fontWeight={500} fontSize={15} lineHeight={"24px"} letterSpacing={"0.25"}>
    {props.children}
  </Typography>
);
