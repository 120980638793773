"use client";

import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

import classes from "./PageLoader.module.css";

interface PageLoaderProps {
  message: string;
}

export const PageLoader: FC<PageLoaderProps> = (props) => {
  const { message } = props;

  return (
    <Box sx={{ height: 400, display: "grid", placeItems: "center" }}>
      <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
        <Box className={classes.loader} />
        <Typography variant="h5" fontSize={15} align="center">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};
