export const ValidatorIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0526 9.41202C16.0526 12.6737 13.6848 15.7285 10.4498 16.6223C10.2297 16.6823 9.98957 16.6823 9.76946 16.6223C6.53451 15.7285 4.16666 12.6737 4.16666 9.41202V6.48387C4.16666 5.93693 4.58021 5.31662 5.0938 5.10985L8.80898 3.58911C9.64273 3.24894 10.5832 3.24894 11.417 3.58911L15.1322 5.10985C15.6391 5.31662 16.0593 5.93693 16.0593 6.48387L16.0526 9.41202Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.11 10.3321C10.8468 10.3321 11.444 9.73481 11.444 8.99806C11.444 8.26132 10.8468 7.66406 10.11 7.66406C9.37328 7.66406 8.77603 8.26132 8.77603 8.99806C8.77603 9.73481 9.37328 10.3321 10.11 10.3321ZM10.11 10.3321V12.3331"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
