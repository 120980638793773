import type { AxiosResponse } from "axios";

/**
 * Downloads the response data as a file.
 *
 * @param response - The Axios response containing the file data.
 */
export const downloadAsFile = (response: AxiosResponse<Blob>, filename: string) => {
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
  link.remove();
};
