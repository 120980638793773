import { Container, type ContainerProps } from "@mui/material";
import { type FC, type ReactNode } from "react";

interface PageContentProps extends ContainerProps {
  children: ReactNode;

  /**
   * If `true`, the content will be contained within the sidebar.
   */
  contained?: boolean;
}

/**
 * Renders the main content of a page.
 *
 * @component
 * @example
 * ```tsx
 * <PageContent>
 *   <h1>Welcome to my website!</h1>
 *   <p>This is the main content of the page.</p>
 * </PageContent>
 * ```
 */
export const PageContent: FC<PageContentProps> = (props) => {
  const { children, contained, ...containerProps } = props;
  const { sx, ...restContainerProps } = containerProps;

  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 6,
        px: { xs: !contained ? 3 : 2, sm: !contained ? 1 : 6, lg: !contained ? 1 : 4, xl: 16 },
        animation: "fadeIn 300ms ease-in-out",
        ...sx,
      }}
      fixed
      {...restContainerProps}
    >
      {children}
    </Container>
  );
};
