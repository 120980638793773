import { SvgIcon } from "@mui/material";
import React from "react";

export const TelegramIcon = () => (
  <SvgIcon>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10417 14.7038C13.4003 12.8359 16.2655 11.6018 17.6997 11.0014C21.7924 9.30027 22.643 9.00007 23.2033 9.00007C23.4063 8.99761 23.6052 9.05694 23.7737 9.17018C23.8908 9.27304 23.9657 9.41563 23.9838 9.57044C24.0054 9.7633 24.0054 9.95796 23.9838 10.1508C23.7637 12.4823 22.8031 18.156 22.3127 20.7477C22.1126 21.8584 21.7023 22.2287 21.3121 22.2587C20.4515 22.3387 19.8011 21.6983 18.9706 21.148C17.6797 20.2974 16.9693 19.7771 15.6884 18.9465C14.2275 17.9459 15.1681 17.4556 15.9986 16.595C16.2188 16.3649 20.0012 12.9426 20.0613 12.5924C20.071 12.5512 20.0715 12.5083 20.0629 12.4668C20.0542 12.4253 20.0366 12.3862 20.0112 12.3522C19.9643 12.33 19.9131 12.3185 19.8612 12.3185C19.8092 12.3185 19.758 12.33 19.7111 12.3522C19.5843 12.3522 17.5096 13.7231 13.487 16.4649C13.0328 16.8256 12.4756 17.0324 11.896 17.0553C11.1145 16.9608 10.3459 16.7795 9.60449 16.515C8.67389 16.2148 7.94341 16.0547 8.00345 15.5143C8.00345 15.2541 8.40371 14.984 9.10417 14.7038Z"
        fill="#260604"
        fillOpacity="0.72"
      />
    </svg>
  </SvgIcon>
);
