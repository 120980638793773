import React from "react";

export const RefreshIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 16.5C8.46 16.5 7.06 15.61 6.11 14.73V15.44C6.11 15.85 5.77 16.19 5.36 16.19C4.95 16.19 4.61 15.85 4.61 15.44V12.69C4.61 12.28 4.95 11.94 5.36 11.94H7.84C8.25 11.94 8.59 12.28 8.59 12.69C8.59 13.1 8.25 13.44 7.84 13.44H6.94C7.68 14.18 8.83 15 10.25 15C12.87 15 15 12.87 15 10.25C15 9.83999 15.34 9.49999 15.75 9.49999C16.16 9.49999 16.5 9.83999 16.5 10.25C16.5 13.7 13.7 16.5 10.25 16.5ZM4.75 11C4.34 11 4 10.66 4 10.25C4 6.8 6.8 3.99999 10.25 3.99999C12.4 3.99999 13.98 4.92999 15 5.81999V5.05999C15 4.64999 15.34 4.30999 15.75 4.30999C16.16 4.30999 16.5 4.64999 16.5 5.05999V7.79999C16.5 7.82999 16.5 7.85999 16.5 7.87999C16.49 7.98999 16.46 8.08999 16.41 8.17999C16.36 8.26999 16.29 8.34999 16.2 8.41999C16.13 8.46999 16.05 8.50999 15.96 8.53999C15.89 8.55999 15.82 8.56999 15.75 8.56999H13.32C12.91 8.56999 12.57 8.22999 12.57 7.81999C12.57 7.40999 12.91 7.06999 13.32 7.06999H14.15C13.35 6.32999 12.06 5.50999 10.27 5.50999C7.65 5.50999 5.52 7.64 5.52 10.26C5.5 10.66 5.16 11 4.75 11Z"
      fill="currentColor"
      fillOpacity="0.93"
    />
  </svg>
);
