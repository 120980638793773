import React from "react";

export const MachineIcons = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33334 8V12.6667M5.42006 8C5.72007 9.16668 6.78674 10.0334 8.04676 10.0267L10.3334 10.02C12.0801 10.0134 13.5668 11.1334 14.1135 12.6934"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.50003 7.66736C6.69666 7.66736 7.66672 6.6973 7.66672 5.50067C7.66672 4.30404 6.69666 3.33398 5.50003 3.33398C4.3034 3.33398 3.33334 4.30404 3.33334 5.50067C3.33334 6.6973 4.3034 7.66736 5.50003 7.66736Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33336 16.6673C6.43794 16.6673 7.33338 15.7719 7.33338 14.6673C7.33338 13.5627 6.43794 12.6673 5.33336 12.6673C4.22878 12.6673 3.33334 13.5627 3.33334 14.6673C3.33334 15.7719 4.22878 16.6673 5.33336 16.6673Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 16.6673C15.7716 16.6673 16.6671 15.7719 16.6671 14.6673C16.6671 13.5627 15.7716 12.6673 14.667 12.6673C13.5625 12.6673 12.667 13.5627 12.667 14.6673C12.667 15.7719 13.5625 16.6673 14.667 16.6673Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
