import React from "react";

export const DashboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5004 8.61046H13.6115C14.7226 8.61046 15.2782 8.0549 15.2782 6.94379V5.83268C15.2782 4.72157 14.7226 4.16602 13.6115 4.16602H12.5004C11.3893 4.16602 10.8338 4.72157 10.8338 5.83268V6.94379C10.8338 8.0549 11.3893 8.61046 12.5004 8.61046Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83375 15.2771H6.94486C8.05597 15.2771 8.61153 14.7216 8.61153 13.6105V12.4993C8.61153 11.3882 8.05597 10.8327 6.94486 10.8327H5.83375C4.72264 10.8327 4.16708 11.3882 4.16708 12.4993V13.6105C4.16708 14.7216 4.72264 15.2771 5.83375 15.2771Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38888 8.61046C7.61618 8.61046 8.6111 7.61554 8.6111 6.38824C8.6111 5.16094 7.61618 4.16602 6.38888 4.16602C5.16158 4.16602 4.16666 5.16094 4.16666 6.38824C4.16666 7.61554 5.16158 8.61046 6.38888 8.61046Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0555 15.2771C14.2828 15.2771 15.2778 14.2822 15.2778 13.0549C15.2778 11.8276 14.2828 10.8327 13.0555 10.8327C11.8282 10.8327 10.8333 11.8276 10.8333 13.0549C10.8333 14.2822 11.8282 15.2771 13.0555 15.2771Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
