import { Box, type BoxProps, Typography, type TypographyProps } from "@mui/material";
import React, { type FC } from "react";

interface TextContentProps extends BoxProps {
  text: string;

  TypographyProps?: TypographyProps;
}

/**
 * Renders a component that displays text content with customizable typography props.
 *
 * @component
 * @example
 * ```tsx
 * <TextContent
 *   text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
 *   TypographyProps={{ variant: 'body1', color: 'primary' }}
 *   marginTop={2}
 * />
 * ```
 *
 * @param {TextContentProps} props - The props for the TextContent component.
 * @returns {JSX.Element} The rendered TextContent component.
 */
export const TextContent: FC<TextContentProps> = (props) => {
  const { text, TypographyProps, ...boxProps } = props;

  return (
    <Box display="flex" flexDirection="column" gap={2} {...boxProps}>
      {text.split("\n").map((line, index) => (
        <Typography key={index} {...TypographyProps}>
          {line}
        </Typography>
      ))}
    </Box>
  );
};
