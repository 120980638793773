"use client";

import { Alert, Button, type ButtonProps, Snackbar } from "@mui/material";
import axios from "axios";
import React, { type FC, useEffect, useState } from "react";

import { downloadAsFile } from "./download-button-utils";

interface DownloadButtonProps extends Omit<ButtonProps, "disabled"> {
  url: string;
  filename: string;
  withCredentials?: boolean;
}

export const DownloadButton: FC<DownloadButtonProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<unknown>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const { url, withCredentials, filename, onClick, ...rest } = props;

  useEffect(() => {
    if (data == null) {
      return undefined;
    }

    const timer = setTimeout(() => {
      setData(null);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  const download = async () => {
    try {
      setIsError(false);
      setData(null);
      setLoading(true);
      const response = await axios.get<Blob>(url, {
        responseType: "blob",
        withCredentials: withCredentials ?? false,
      });
      setData(response.data);
      downloadAsFile(response, filename);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        {...rest}
        onClick={(e) => {
          onClick?.(e);
          void download();
        }}
        disabled={loading}
      />

      {loading && (
        <Snackbar open>
          <Alert severity="info">Downloading...</Alert>
        </Snackbar>
      )}
      {!loading && data && (
        <Snackbar open>
          <Alert severity="success">File downloaded successfully</Alert>
        </Snackbar>
      )}
      {isError && (
        <Snackbar open>
          <Alert severity="error">Failed to download file</Alert>
        </Snackbar>
      )}
    </>
  );
};
