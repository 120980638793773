import { Box, Skeleton, Typography } from "@mui/material";
import React, { type FC } from "react";

const DEFAULT_COLOR = "var(--graph-color-2)";

interface ChartLoadingProps {
  loading?: boolean;
  label: string;
  value: string | number;
  color?: string;
}

export const ChartLegend: FC<ChartLoadingProps> = (props) => {
  const { loading, value, color, label } = props;
  return (
    <Box
      component="span"
      sx={{
        position: "relative",
        "&::before": {
          content: "''",
          height: 9,
          width: 9,
          position: "relative",
          transform: "translateY(1px)",
          display: "inline-flex",
          borderRadius: Number.MAX_SAFE_INTEGER,
          backgroundColor: color ?? DEFAULT_COLOR,
          mr: 1,
        },
      }}
    >
      <Typography component="span" display="inline-flex" alignItems="center" fontSize={9} fontWeight={500} color="var(--text-medium-emphasis)">
        {label}:&nbsp;&nbsp;
        <Typography component="span" fontSize="inherit" fontWeight="inherit" color="var(--text-high-emphasis)">
          {loading ? <Skeleton variant="text" component="span" height="14" width={40} /> : value}
        </Typography>
      </Typography>
    </Box>
  );
};
