import { Box } from "@mui/material";
import type { FC } from "react";

interface LinearProgressProps {
  /**
   * Represents the progress done in percentage.
   *
   * Value should be between 0 and 1.
   */
  done: number;

  label?: string;

  backgroundColor?: string;

  color?: string;
}

const DEFAULT_BACKGROUND_COLOR = "var(--primary-color)";
const DEFAULT_COLOR = "white";
const DEFAULT_BORDER_RADIUS = "1rem";
const DEFAULT_PROGRESS_HEIGHT = 24;

/**
 * LinearProgress component represents a linear progress bar.
 *
 * @component
 * @example
 * ```tsx
 * <LinearProgress done={50} total={100} label="Progress" />
 * ```
 */
export const LinearProgress: FC<LinearProgressProps> = (props) => {
  const { done, label, backgroundColor, color } = props;
  const width = `max(${done * 100}%, ${(label?.length ?? 0) * 8 + 32}px)`;

  return (
    <Box
      sx={{
        height: DEFAULT_PROGRESS_HEIGHT,
        borderRadius: DEFAULT_BORDER_RADIUS,
        overflow: "hidden",
        border: "var(--border-default)",
      }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-flex",
          alignItems: "center",
          padding: "0 1rem",
          height: "100%",
          width,
          borderRadius: DEFAULT_BORDER_RADIUS,
          textWrap: "nowrap",
          backgroundColor: backgroundColor ?? DEFAULT_BACKGROUND_COLOR,
          color: color ?? DEFAULT_COLOR,
          fontSize: 9,
          fontWeight: 700,
        }}
      >
        {label}
      </Box>
    </Box>
  );
};
