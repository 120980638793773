import { SvgIcon } from "@mui/material";
import type { FC } from "react";

interface Props {
  /**
   * The size of the icon.
   */
  size?: number;
}

const DEFAULT_SIZE = 16;

/**
 * Renders the CipherNode logo as an SVG icon.
 */
export const ApplicationLogo: FC<Props> = (props) => (
  <SvgIcon sx={{ fontSize: props.size ?? DEFAULT_SIZE }}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.72 0H4.28C1.91622 0 0 1.91622 0 4.28V35.72C0 38.0838 1.91622 40 4.28 40H35.72C38.0838 40 40 38.0838 40 35.72V4.28C40 1.91622 38.0838 0 35.72 0Z"
        fill="#BE0916"
      />
      <path
        d="M25.84 16.12C26.92 16.12 27.8 15.24 27.8 14.16C27.8 13.08 26.92 12.2 25.84 12.2C24.76 12.2 23.88 13.08 23.88 14.16C23.88 15.24 24.76 16.12 25.84 16.12Z"
        fill="#FFF8F7"
      />
      <path
        d="M25.84 10.28C26.92 10.28 27.8 9.4 27.8 8.32C27.8 7.24 26.92 6.36 25.84 6.36C24.76 6.36 23.88 7.24 23.88 8.32C23.88 9.4 24.76 10.28 25.84 10.28Z"
        fill="#FFF8F7"
      />
      <path
        d="M20 16.12C21.08 16.12 21.96 15.24 21.96 14.16C21.96 13.08 21.08 12.2 20 12.2C18.92 12.2 18.04 13.08 18.04 14.16C18.04 15.24 18.92 16.12 20 16.12Z"
        fill="#FFF8F7"
      />
      <path
        d="M14.16 16.12C15.24 16.12 16.12 15.24 16.12 14.16C16.12 13.08 15.24 12.2 14.16 12.2C13.08 12.2 12.2 13.08 12.2 14.16C12.2 15.24 13.08 16.12 14.16 16.12Z"
        fill="#FFF8F7"
      />
      <path
        d="M8.32 16.12C9.4 16.12 10.28 15.24 10.28 14.16C10.28 13.08 9.4 12.2 8.32 12.2C7.24 12.2 6.36 13.08 6.36 14.16C6.36 15.24 7.24 16.12 8.32 16.12Z"
        fill="#FFF8F7"
      />
      <path
        d="M31.68 16.12C32.76 16.12 33.64 15.24 33.64 14.16C33.64 13.08 32.76 12.2 31.68 12.2C30.6 12.2 29.72 13.08 29.72 14.16C29.72 15.24 30.6 16.12 31.68 16.12Z"
        fill="#FFF8F7"
      />
      <path
        d="M25.84 27.76C26.92 27.76 27.8 26.88 27.8 25.8C27.8 24.72 26.92 23.84 25.84 23.84C24.76 23.84 23.88 24.72 23.88 25.8C23.88 26.88 24.76 27.76 25.84 27.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M25.84 33.6C26.92 33.6 27.8 32.72 27.8 31.64C27.8 30.56 26.92 29.68 25.84 29.68C24.76 29.68 23.88 30.56 23.88 31.64C23.88 32.72 24.76 33.6 25.84 33.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M14.16 27.76C15.24 27.76 16.12 26.88 16.12 25.8C16.12 24.72 15.24 23.84 14.16 23.84C13.08 23.84 12.2 24.72 12.2 25.8C12.2 26.88 13.08 27.76 14.16 27.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M8.32 27.76C9.4 27.76 10.28 26.88 10.28 25.8C10.28 24.72 9.4 23.84 8.32 23.84C7.24 23.84 6.36 24.72 6.36 25.8C6.36 26.88 7.24 27.76 8.32 27.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M14.16 33.6C15.24 33.6 16.12 32.72 16.12 31.64C16.12 30.56 15.24 29.68 14.16 29.68C13.08 29.68 12.2 30.56 12.2 31.64C12.2 32.72 13.08 33.6 14.16 33.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M8.32 10.28C9.4 10.28 10.28 9.4 10.28 8.32C10.28 7.24 9.4 6.36 8.32 6.36C7.24 6.36 6.36 7.24 6.36 8.32C6.36 9.4 7.24 10.28 8.32 10.28Z"
        fill="#FFF8F7"
      />
      <path
        d="M8.32 21.96C9.4 21.96 10.28 21.08 10.28 20C10.28 18.92 9.4 18.04 8.32 18.04C7.24 18.04 6.36 18.92 6.36 20C6.36 21.08 7.24 21.96 8.32 21.96Z"
        fill="#FFF8F7"
      />
      <path
        d="M8.32 33.6C9.4 33.6 10.28 32.72 10.28 31.64C10.28 30.56 9.4 29.68 8.32 29.68C7.24 29.68 6.36 30.56 6.36 31.64C6.36 32.72 7.24 33.6 8.32 33.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M20 33.6C21.08 33.6 21.96 32.72 21.96 31.64C21.96 30.56 21.08 29.68 20 29.68C18.92 29.68 18.04 30.56 18.04 31.64C18.04 32.72 18.92 33.6 20 33.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M31.68 33.6C32.76 33.6 33.64 32.72 33.64 31.64C33.64 30.56 32.76 29.68 31.68 29.68C30.6 29.68 29.72 30.56 29.72 31.64C29.72 32.72 30.6 33.6 31.68 33.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M14.16 10.28C15.24 10.28 16.12 9.4 16.12 8.32C16.12 7.24 15.24 6.36 14.16 6.36C13.08 6.36 12.2 7.24 12.2 8.32C12.2 9.4 13.08 10.28 14.16 10.28Z"
        fill="#FFF8F7"
      />
      <path
        d="M31.68 10.28C32.76 10.28 33.64 9.4 33.64 8.32C33.64 7.24 32.76 6.36 31.68 6.36C30.6 6.36 29.72 7.24 29.72 8.32C29.72 9.4 30.6 10.28 31.68 10.28Z"
        fill="#FFF8F7"
      />
      <path
        d="M20 27.76C21.08 27.76 21.96 26.88 21.96 25.8C21.96 24.72 21.08 23.84 20 23.84C18.92 23.84 18.04 24.72 18.04 25.8C18.04 26.88 18.92 27.76 20 27.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M31.68 27.76C32.76 27.76 33.64 26.88 33.64 25.8C33.64 24.72 32.76 23.84 31.68 23.84C30.6 23.84 29.72 24.72 29.72 25.8C29.72 26.88 30.6 27.76 31.68 27.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M29.72 14.16C29.72 13.08 30.6 12.2 31.68 12.2C30.68 12.2 29.8 11.84 29.12 11.24C29 11.36 28.88 11.48 28.76 11.6C29.36 12.28 29.72 13.2 29.72 14.16Z"
        fill="#FFF8F7"
      />
      <path
        d="M28.76 11.6C28.76 11.6 29 11.36 29.12 11.24C29 11.12 28.88 11 28.76 10.88C28.64 11 28.52 11.12 28.4 11.24C28.52 11.36 28.64 11.48 28.76 11.6Z"
        fill="#FFF8F7"
      />
      <path
        d="M27.76 8.32C27.76 9.4 26.88 10.28 25.8 10.28C26.8 10.28 27.68 10.64 28.36 11.24C28.48 11.12 28.6 11 28.72 10.88C28.12 10.2 27.76 9.28 27.76 8.32Z"
        fill="#FFF8F7"
      />
      <path
        d="M23.88 14.16C23.88 13.08 24.76 12.2 25.84 12.2C24.84 12.2 23.96 11.84 23.28 11.24C23.16 11.36 23.04 11.48 22.92 11.6C23.52 12.28 23.88 13.2 23.88 14.16Z"
        fill="#FFF8F7"
      />
      <path
        d="M23.28 11.24C23.28 11.24 23.04 11 22.92 10.88C22.8 11 22.68 11.12 22.56 11.24C22.68 11.36 22.8 11.48 22.92 11.6C23.04 11.48 23.16 11.36 23.28 11.24Z"
        fill="#FFF8F7"
      />
      <path
        d="M20 10.28C21.08 10.28 21.96 9.4 21.96 8.32C21.96 7.24 21.08 6.36 20 6.36C18.92 6.36 18.04 7.24 18.04 8.32C18.04 9.4 18.92 10.28 20 10.28Z"
        fill="#FFF8F7"
      />
      <path
        d="M21.96 8.32C21.96 9.4 21.08 10.28 20 10.28C21 10.28 21.88 10.64 22.56 11.24C22.68 11.12 22.8 11 22.92 10.88C22.32 10.2 21.96 9.28 21.96 8.32Z"
        fill="#FFF8F7"
      />
      <path
        d="M18.04 14.16C18.04 13.08 18.92 12.2 20 12.2C19 12.2 18.12 11.84 17.44 11.24C17.32 11.36 17.2 11.48 17.08 11.6C17.68 12.28 18.04 13.2 18.04 14.16Z"
        fill="#FFF8F7"
      />
      <path
        d="M17.44 11.24C17.44 11.24 17.2 11 17.08 10.88C16.96 11 16.84 11.12 16.72 11.24C16.84 11.36 16.96 11.48 17.08 11.6C17.2 11.48 17.32 11.36 17.44 11.24Z"
        fill="#FFF8F7"
      />
      <path
        d="M16.12 8.32C16.12 9.4 15.24 10.28 14.16 10.28C15.16 10.28 16.04 10.64 16.72 11.24C16.84 11.12 16.96 11 17.08 10.88C16.48 10.2 16.12 9.28 16.12 8.32Z"
        fill="#FFF8F7"
      />
      <path
        d="M12.24 14.16C12.24 13.08 13.12 12.2 14.2 12.2C13.2 12.2 12.32 11.84 11.64 11.24C11.52 11.36 11.4 11.48 11.28 11.6C11.88 12.28 12.24 13.2 12.24 14.16Z"
        fill="#FFF8F7"
      />
      <path
        d="M11.6 11.24C11.6 11.24 11.36 11 11.24 10.88C11.12 11 11 11.12 10.88 11.24C11 11.36 11.12 11.48 11.24 11.6C11.36 11.48 11.48 11.36 11.6 11.24Z"
        fill="#FFF8F7"
      />
      <path
        d="M10.28 8.32C10.28 9.4 9.4 10.28 8.32 10.28C9.32 10.28 10.2 10.64 10.88 11.24C11 11.12 11.12 11 11.24 10.88C10.64 10.2 10.28 9.28 10.28 8.32Z"
        fill="#FFF8F7"
      />
      <path
        d="M10.28 25.84C10.28 26.92 9.4 27.8 8.32 27.8C9.32 27.8 10.2 28.16 10.88 28.76C11 28.64 11.12 28.52 11.24 28.4C10.64 27.72 10.28 26.8 10.28 25.84Z"
        fill="#FFF8F7"
      />
      <path
        d="M11.24 29.08C11.24 29.08 11.48 28.84 11.6 28.72C11.48 28.6 11.36 28.48 11.24 28.36C11.12 28.48 11 28.6 10.88 28.72C11 28.84 11.12 28.96 11.24 29.08Z"
        fill="#FFF8F7"
      />
      <path
        d="M12.24 31.68C12.24 30.6 13.12 29.72 14.2 29.72C13.2 29.72 12.32 29.36 11.64 28.76C11.52 28.88 11.4 29 11.28 29.12C11.88 29.8 12.24 30.72 12.24 31.68Z"
        fill="#FFF8F7"
      />
      <path
        d="M16.12 25.84C16.12 26.92 15.24 27.8 14.16 27.8C15.16 27.8 16.04 28.16 16.72 28.76C16.84 28.64 16.96 28.52 17.08 28.4C16.48 27.72 16.12 26.8 16.12 25.84Z"
        fill="#FFF8F7"
      />
      <path
        d="M17.08 28.4C17.08 28.4 16.84 28.64 16.72 28.76C16.84 28.88 16.96 29 17.08 29.12C17.2 29 17.32 28.88 17.44 28.76C17.32 28.64 17.2 28.52 17.08 28.4Z"
        fill="#FFF8F7"
      />
      <path
        d="M18.04 31.68C18.04 30.6 18.92 29.72 20 29.72C19 29.72 18.12 29.36 17.44 28.76C17.32 28.88 17.2 29 17.08 29.12C17.68 29.8 18.04 30.72 18.04 31.68Z"
        fill="#FFF8F7"
      />
      <path
        d="M21.96 25.84C21.96 26.92 21.08 27.8 20 27.8C21 27.8 21.88 28.16 22.56 28.76C22.68 28.64 22.8 28.52 22.92 28.4C22.32 27.72 21.96 26.8 21.96 25.84Z"
        fill="#FFF8F7"
      />
      <path
        d="M23.28 28.76C23.28 28.76 23.04 28.52 22.92 28.4C22.8 28.52 22.68 28.64 22.56 28.76C22.68 28.88 22.8 29 22.92 29.12C23.04 29 23.16 28.88 23.28 28.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M23.88 31.68C23.88 30.6 24.76 29.72 25.84 29.72C24.84 29.72 23.96 29.36 23.28 28.76C23.16 28.88 23.04 29 22.92 29.12C23.52 29.8 23.88 30.72 23.88 31.68Z"
        fill="#FFF8F7"
      />
      <path
        d="M27.76 25.84C27.76 26.92 26.88 27.8 25.8 27.8C26.8 27.8 27.68 28.16 28.36 28.76C28.48 28.64 28.6 28.52 28.72 28.4C28.12 27.72 27.76 26.8 27.76 25.84Z"
        fill="#FFF8F7"
      />
      <path
        d="M29.08 28.76C29.08 28.76 28.84 28.52 28.72 28.4C28.6 28.52 28.48 28.64 28.36 28.76C28.48 28.88 28.6 29 28.72 29.12C28.84 29 28.96 28.88 29.08 28.76Z"
        fill="#FFF8F7"
      />
      <path
        d="M29.72 31.68C29.72 30.6 30.6 29.72 31.68 29.72C30.68 29.72 29.8 29.36 29.12 28.76C29 28.88 28.88 29 28.76 29.12C29.36 29.8 29.72 30.72 29.72 31.68Z"
        fill="#FFF8F7"
      />
      <path
        d="M14.16 21.96C15.24 21.96 16.12 21.08 16.12 20C16.12 18.92 15.24 18.04 14.16 18.04C13.08 18.04 12.2 18.92 12.2 20C12.2 21.08 13.08 21.96 14.16 21.96Z"
        fill="#FFF8F7"
      />
    </svg>
  </SvgIcon>
);
