import { Box, type BoxProps, type SxProps, type Theme } from "@mui/material";
import React, { type FC } from "react";

interface ClipTextProps extends BoxProps {
  text: string;

  sx?: SxProps<Theme>;

  ellipsis?: boolean;
}

export const ClipText: FC<ClipTextProps> = (props) => {
  const { text, sx, ellipsis, ...restProps } = props;
  const textOverflow = ellipsis ? "ellipsis" : "clip";

  return (
    <Box width={"100%"} display={"inline-flex"} flex={1}>
      <Box component="span" sx={{ display: "inline-block", whiteSpace: "nowrap", textOverflow, overflow: "hidden", flex: 1, ...sx }} {...restProps}>
        {text}
      </Box>
    </Box>
  );
};
