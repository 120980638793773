import React from "react";

export const PendingRequestIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.449 8.69953V6.41372C15.449 5.59403 14.8226 4.6877 14.0562 4.40114L10.7308 3.15494C10.1777 2.94835 9.27136 2.94835 8.71823 3.15494L5.39281 4.40781C4.62643 4.69436 4 5.60069 4 6.41372V11.3652C4 12.1516 4.5198 13.1845 5.1529 13.6577L8.01849 15.7969C8.48498 16.1567 9.10475 16.33 9.72452 16.33M15.7193 15.6636H15.7253M15.0558 12.3315C15.0558 13.8037 13.8624 14.9972 12.3902 14.9972C10.918 14.9972 9.72451 13.8037 9.72451 12.3315C9.72451 10.8593 10.918 9.66586 12.3902 9.66586C13.8624 9.66586 15.0558 10.8593 15.0558 12.3315Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
