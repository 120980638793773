import React from "react";

export const BackIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.707 7.70711C11.0975 7.31658 11.0975 6.68342 10.707 6.29289C10.3164 5.90237 9.68327 5.90237 9.29274 6.29289L4.99985 10.5858C4.2188 11.3668 4.2188 12.6332 4.99985 13.4142L9.29274 17.7071C9.68327 18.0976 10.3164 18.0976 10.707 17.7071C11.0975 17.3166 11.0975 16.6834 10.707 16.2929L7.41419 13.0001H18.9998C19.5521 13.0001 19.9998 12.5524 19.9998 12.0001C19.9998 11.4478 19.5521 11.0001 18.9998 11.0001H7.41394L10.707 7.70711Z"
      fill="#1F0B09"
      fillOpacity="0.72"
    />
  </svg>
);
