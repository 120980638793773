import React from "react";

export const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 3.25C10.4142 3.25 10.75 3.58579 10.75 4V11.1893L12.4697 9.46967C12.7626 9.17678 13.2374 9.17678 13.5303 9.46967C13.8232 9.76256 13.8232 10.2374 13.5303 10.5303L10.5303 13.5303C10.2374 13.8232 9.76256 13.8232 9.46967 13.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L9.25 11.1893V4C9.25 3.58579 9.58579 3.25 10 3.25Z"
      fill="#FFEDEA"
      fillOpacity="0.9"
    />
    <path
      d="M4 12.25C4.41421 12.25 4.75 12.5858 4.75 13V15.25H15.25V13C15.25 12.5858 15.5858 12.25 16 12.25C16.4142 12.25 16.75 12.5858 16.75 13V16C16.75 16.4142 16.4142 16.75 16 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16V13C3.25 12.5858 3.58579 12.25 4 12.25Z"
      fill="#FFEDEA"
      fillOpacity="0.9"
    />
  </svg>
);
