import React from "react";

export const SearchIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.21 18.29L15.92 14H15L14.84 13.84C15.87 12.69 16.5 11.17 16.5 9.5C16.5 5.91 13.59 3 10 3C6.41 3 3.5 5.91 3.5 9.5C3.5 13.09 6.41 16 10 16C11.67 16 13.19 15.37 14.34 14.34L14.5 14.5V15.42L18.79 19.71C18.99 19.9 19.24 20 19.5 20C19.76 20 20.01 19.9 20.21 19.71C20.6 19.32 20.6 18.68 20.21 18.29ZM10 14C7.51 14 5.5 11.99 5.5 9.5C5.5 7.01 7.51 5 10 5C12.49 5 14.5 7.01 14.5 9.5C14.5 11.99 12.49 14 10 14Z"
      fill="#260604"
      fillOpacity="0.46"
    />
  </svg>
);
