import { SvgIcon } from "@mui/material";
import React from "react";

export const FacebookIcon = () => (
  <SvgIcon>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.29 24C14.21 23.94 14.16 23.86 14.12 23.77C14.09 23.68 14.07 23.58 14.09 23.48V17.22C14.09 17.08 14.09 17.03 13.91 17.04H12.09C11.79 17.04 11.67 16.92 11.67 16.63V14.17C11.67 13.88 11.8 13.76 12.09 13.76H14.01C14.12 13.76 14.15 13.76 14.15 13.61V11.61C14.15 11.1 14.26 10.6 14.47 10.14C14.68 9.68 14.98 9.26 15.35 8.92C15.91 8.42 16.61 8.12 17.35 8.05C18.22 8 19.12 8 20.01 8C20.09 8 20.16 8.03 20.22 8.08C20.28 8.13 20.31 8.2 20.32 8.28C20.32 8.34 20.32 8.39 20.32 8.45V10.65C20.32 11 20.21 11.1 19.87 11.11H18.34C18.22 11.11 18.1 11.11 17.98 11.16C17.87 11.2 17.76 11.27 17.68 11.36C17.6 11.45 17.53 11.55 17.49 11.67C17.45 11.78 17.44 11.91 17.45 12.03V13.63C17.45 13.74 17.45 13.75 17.58 13.75H19.8C20.1 13.75 20.22 13.86 20.22 14.17V16.62C20.22 16.92 20.1 17.03 19.8 17.03H17.61C17.48 17.03 17.45 17.03 17.45 17.19V23.52C17.45 23.61 17.45 23.7 17.41 23.79C17.37 23.87 17.31 23.94 17.23 23.99L14.28 24.01L14.29 24Z"
        fill="#260604"
        fillOpacity="0.72"
      />
    </svg>
  </SvgIcon>
);
