"use client";

import type { TableRowProps as MuiTableRowProps } from "@mui/material";
import { styled, TableRow as MuiTableRow } from "@mui/material";

interface TableRowProps extends MuiTableRowProps {
  stripped?: boolean;
}

export const TableRow = styled(MuiTableRow, { shouldForwardProp: (prop) => prop !== "stripped" })<TableRowProps>(({ stripped }) => {
  const borderRadius = "1rem";

  return {
    "&:first-child": {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    },
    "&:last-child": {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: stripped ? "var(--surface-base-level-2)" : undefined,
    },
  };
});
