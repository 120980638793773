"use client";

import { Button } from "@mui/material";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { type FC, useEffect } from "react";

interface IProps {
  children: React.ReactNode;
}

export const PrevRoute: FC<IProps> = (props) => {
  const { children } = props;
  const router = useRouter();

  const handleOnClick = () => {
    const item = sessionStorage.getItem("page-navigation-count");

    if (item == null) {
      router.push("/");
      return;
    }

    router.back();
  };

  return (
    <Button variant="ghost" onClick={handleOnClick} aria-label="prev-page-button">
      {children}
    </Button>
  );
};

export const PreservePrevRoute = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    window.onload = () => {
      sessionStorage.removeItem("page-navigation-count");
    };
  }, []);

  useEffect(() => {
    const pageNavigationCount = parseInt(sessionStorage.getItem("page-navigation-count") ?? "0", 10);
    sessionStorage.setItem("page-navigation-count", (pageNavigationCount + 1).toString());
  }, [pathname, searchParams]);
  return null;
};
