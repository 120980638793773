import React from "react";

export const RedirectIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6.75C5.30964 6.75 4.75 7.30964 4.75 8V14C4.75 14.6904 5.30964 15.25 6 15.25H12C12.6904 15.25 13.25 14.6904 13.25 14V11C13.25 10.5858 13.5858 10.25 14 10.25C14.4142 10.25 14.75 10.5858 14.75 11V14C14.75 15.5188 13.5188 16.75 12 16.75H6C4.48122 16.75 3.25 15.5188 3.25 14V8C3.25 6.48122 4.48122 5.25 6 5.25H9C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75H6Z"
      fill="currentColor"
      fillOpacity="0.93"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 4C11.25 3.58579 11.5858 3.25 12 3.25H16C16.4142 3.25 16.75 3.58579 16.75 4V8C16.75 8.41421 16.4142 8.75 16 8.75C15.5858 8.75 15.25 8.41421 15.25 8V5.81066L10.5303 10.5303C10.2374 10.8232 9.76256 10.8232 9.46967 10.5303C9.17678 10.2374 9.17678 9.76256 9.46967 9.46967L14.1893 4.75H12C11.5858 4.75 11.25 4.41421 11.25 4Z"
      fill="currentColor"
      fillOpacity="0.93"
    />
  </svg>
);
