import type { SVGProps } from "react";

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#260604"
      fillOpacity={0.72}
      d="M13.289 16.293a1 1 0 0 0 1.414 1.414l4.293-4.293a2 2 0 0 0 0-2.828l-4.293-4.293a1 1 0 1 0-1.414 1.414L16.582 11H5a1 1 0 1 0 0 2h11.581l-3.292 3.293Z"
    />
  </svg>
);
