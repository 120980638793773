import { Step, StepLabel, Stepper as MuiStepper, Typography } from "@mui/material";
import type { FC, Key } from "react";

interface StepperProps {
  steps: { label: string; key?: Key }[];

  activeStep: number;
}

/**
 * Stepper component displays a sequence of steps in a horizontal or vertical layout.
 *
 * @component
 * @example
 * ```tsx
 * <Stepper steps={steps} activeStep={activeStep} />
 * ```
 *
 * @param {StepperProps} props - The props for the Stepper component.
 * @param {Step[]} props.steps - An array of Step objects representing the steps to be displayed.
 * @param {number} props.activeStep - The index of the currently active step.
 * @returns {JSX.Element} The rendered Stepper component.
 */
export const Stepper: FC<StepperProps> = (props) => {
  const { steps, activeStep } = props;

  return (
    <MuiStepper alternativeLabel activeStep={activeStep}>
      {steps.map((item) => (
        <Step key={item.key}>
          <StepLabel>
            <Typography
              fontSize={9}
              fontWeight={500}
              color="var(--text-medium-emphasis)"
              sx={{ display: { xs: item.key !== activeStep ? "none" : undefined, sm: "unset" } }}
            >
              {item.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};
