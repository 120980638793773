import { SvgIcon } from "@mui/material";

export const TwitterIcon = () => (
  <SvgIcon>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 22.0002C14.2291 22.0095 15.4479 21.7743 16.5853 21.3082C17.7226 20.8421 18.756 20.1545 19.6251 19.2853C20.4943 18.4162 21.1819 17.3828 21.648 16.2455C22.1141 15.1081 22.3493 13.8893 22.34 12.6602C22.34 12.5202 22.34 12.3702 22.34 12.2302C22.9939 11.7592 23.5564 11.173 24 10.5002C23.3983 10.7596 22.7613 10.9281 22.11 11.0002C22.7933 10.5932 23.3079 9.95451 23.56 9.2002C22.9138 9.58354 22.207 9.85407 21.47 10.0002C21.1608 9.6795 20.7892 9.42537 20.3783 9.25341C19.9673 9.08145 19.5255 8.99528 19.08 9.0002C18.2074 9.0002 17.3706 9.34682 16.7536 9.96382C16.1366 10.5808 15.79 11.4176 15.79 12.2902C15.7903 12.5429 15.8205 12.7946 15.88 13.0402C14.5746 12.9729 13.2978 12.6329 12.1318 12.0421C10.9658 11.4514 9.93639 10.6229 9.11 9.6102C8.81888 10.1111 8.66697 10.6808 8.67 11.2602C8.66902 11.8018 8.80177 12.3353 9.05647 12.8132C9.31116 13.2912 9.67991 13.6989 10.13 14.0002C9.60855 13.979 9.09888 13.8388 8.64 13.5902C8.63929 14.3495 8.90208 15.0856 9.38352 15.6728C9.86497 16.2601 10.5352 16.662 11.28 16.8102C10.9956 16.8824 10.7034 16.9193 10.41 16.9202C10.2019 16.9205 9.99421 16.9004 9.79 16.8602C9.99772 17.5143 10.406 18.0865 10.957 18.4957C11.508 18.9049 12.1737 19.1304 12.86 19.1402C11.6969 20.0549 10.2597 20.5516 8.78 20.5502C8.51926 20.5485 8.25883 20.5318 8 20.5002C9.48928 21.4673 11.2243 21.9878 13 22.0002Z"
        fill="#260604"
        fillOpacity="0.72"
      />
    </svg>
  </SvgIcon>
);
