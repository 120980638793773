import { Skeleton, type SkeletonProps, TableCell, type TableCellProps, TableRow } from "@mui/material";
import type { FC } from "react";

export type TableRowsLoaderCellProps = TableCellProps & {
  SkeletonProps?: SkeletonProps;
};

interface TableRowsLoaderProps extends TableCellProps {
  rows: number;

  columns: TableRowsLoaderCellProps[] | number;
}

/**
 * Renders a loader component that displays skeleton rows in a table.
 *
 * @component
 * @example
 * ```tsx
 * <TableRowsLoader rows={5} columns={3} />
 * ```
 *
 * @param {TableRowsLoaderProps} props - The props for the TableRowsLoader component.
 * @param {number} props.rows - The number of rows to render.
 * @param {number} props.columns - The number of columns to render in each row.
 * @returns {JSX.Element[]} An array of JSX elements representing the skeleton rows.
 */
export const TableRowsLoader: FC<TableRowsLoaderProps> = (props: TableRowsLoaderProps): JSX.Element[] => {
  const { rows, columns } = props;
  const cols = typeof columns === "number" ? Array.from({ length: columns }) : columns;

  return Array.from({ length: rows }).map((_, index) => (
    <TableRow key={index}>
      {cols.map((column, i) => {
        const { ...cellProps } = column ?? {};

        return (
          <TableCell key={i} {...cellProps}>
            <Skeleton variant="text" />
          </TableCell>
        );
      })}
    </TableRow>
  ));
};
