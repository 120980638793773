import { SvgIcon } from "@mui/material";
import React from "react";

export const LinkedinIcon = () => (
  <SvgIcon>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6256 9.3999H10.3744C10.116 9.3999 9.86816 9.50256 9.68542 9.6853C9.50269 9.86804 9.40002 10.1159 9.40002 10.3743V21.6255C9.40002 21.8839 9.50269 22.1318 9.68542 22.3145C9.86816 22.4972 10.116 22.5999 10.3744 22.5999H21.6256C21.884 22.5999 22.1319 22.4972 22.3146 22.3145C22.4974 22.1318 22.6 21.8839 22.6 21.6255V10.3743C22.6 10.1159 22.4974 9.86804 22.3146 9.6853C22.1319 9.50256 21.884 9.3999 21.6256 9.3999ZM13.3344 20.6447H11.3498V14.3407H13.3344V20.6447ZM12.3407 13.4672C12.1156 13.4659 11.8959 13.398 11.7093 13.272C11.5228 13.1459 11.3777 12.9675 11.2925 12.7591C11.2073 12.5508 11.1857 12.3218 11.2304 12.1012C11.2752 11.8806 11.3843 11.6782 11.544 11.5195C11.7037 11.3608 11.9068 11.253 12.1277 11.2097C12.3486 11.1663 12.5774 11.1894 12.7852 11.2759C12.9931 11.3625 13.1706 11.5087 13.2954 11.696C13.4202 11.8834 13.4867 12.1035 13.4865 12.3287C13.4886 12.4794 13.4604 12.629 13.4035 12.7685C13.3466 12.9081 13.2621 13.0348 13.1552 13.141C13.0483 13.2473 12.921 13.3309 12.7811 13.3869C12.6412 13.443 12.4914 13.4702 12.3407 13.4672ZM20.6494 20.6502H18.6657V17.2062C18.6657 16.1906 18.2339 15.8771 17.6766 15.8771C17.0881 15.8771 16.5106 16.3207 16.5106 17.2319V20.6502H14.526V14.3453H16.4345V15.2189H16.4602C16.6518 14.8312 17.3228 14.1684 18.3467 14.1684C19.454 14.1684 20.6503 14.8257 20.6503 16.7507L20.6494 20.6502Z"
        fill="#260604"
        fillOpacity="0.72"
      />
    </svg>
  </SvgIcon>
);
