import React, { type FC } from "react";

interface CheckboxIconProps {
  checked?: boolean;
}

export const CheckboxIcon: FC<CheckboxIconProps> = (props) => {
  const { checked } = props;

  return checked ? (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_568_14406)">
        <rect width="20" height="20" rx="6" fill="var(--primary-color)" />
        <path
          d="M6.88006 14.2898L4.29006 11.6998C4.10381 11.5125 3.99927 11.259 3.99927 10.9948C3.99927 10.7306 4.10381 10.4772 4.29006 10.2898C4.47742 10.1036 4.73087 9.99902 4.99506 9.99902C5.25925 9.99902 5.5127 10.1036 5.70006 10.2898L7.58006 12.1698C7.67302 12.2635 7.78362 12.3379 7.90548 12.3887C8.02734 12.4395 8.15805 12.4656 8.29006 12.4656C8.42207 12.4656 8.55278 12.4395 8.67464 12.3887C8.7965 12.3379 8.9071 12.2635 9.00006 12.1698L15.8801 5.28982C16.0674 5.10356 16.3209 4.99902 16.5851 4.99902C16.8492 4.99902 17.1027 5.10356 17.2901 5.28982C17.4763 5.47718 17.5809 5.73063 17.5809 5.99482C17.5809 6.259 17.4763 6.51245 17.2901 6.69982L9.70006 14.2898C9.32534 14.6623 8.81843 14.8714 8.29006 14.8714C7.76169 14.8714 7.25479 14.6623 6.88006 14.2898Z"
          fill="var(--secondary-color-hover)"
          fillOpacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_568_14406">
          <rect width="20" height="20" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="18" height="18" rx="5" stroke="var(--bg-act-n-def)" strokeWidth="2" />
    </svg>
  );
};
