import React from "react";

export const UsersIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.7194 3.15498L5.39316 4.40149C4.62659 4.68812 4 5.59466 4 6.41456V11.3673C4 12.1538 4.51994 13.187 5.1532 13.6603L8.0195 15.8001C8.95938 16.5066 10.5058 16.5066 11.4457 15.8001L14.312 13.6603C14.9453 13.187 15.4652 12.1538 15.4652 11.3673V6.41456C15.4652 5.59466 14.8386 4.68812 14.0721 4.40149L10.7458 3.15498C10.1792 2.94834 9.27266 2.94834 8.7194 3.15498Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.72595 8.9476C9.69928 8.9476 9.66595 8.9476 9.63929 8.9476C9.0127 8.9276 8.51276 8.40766 8.51276 7.77441C8.51276 7.12782 9.03937 6.60121 9.68595 6.60121C10.3325 6.60121 10.8591 7.12782 10.8591 7.77441C10.8525 8.41433 10.3525 8.9276 9.72595 8.9476Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.39945 10.814C7.75953 11.2406 7.75953 11.9406 8.39945 12.3672C9.12603 12.8538 10.3192 12.8538 11.0458 12.3672C11.6857 11.9406 11.6857 11.2406 11.0458 10.814C10.3259 10.3274 9.13269 10.3274 8.39945 10.814Z"
      stroke="currentColor"
      strokeOpacity="0.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
